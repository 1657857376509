<div class="container mt-5">
  <h1>{{ "about.title" | translate }}</h1>
  <p>
    {{ "about.p1" | translate }}
  </p>
  <p>
    {{ "about.p2" | translate }}
  </p>
  <app-contact-btn></app-contact-btn>
</div>
