import { Component } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { ContactBtnComponent } from "../../Core/Components/contact-btn/contact-btn.component";

@Component({
  selector: "app-classic-hosting-services",
  standalone: true,
  imports: [TranslateModule, ContactBtnComponent],
  templateUrl: "./classic-hosting-services.component.html",
  styleUrl: "./classic-hosting-services.component.scss",
})
export class ClassicHostingServicesComponent {}
