import { CommonModule } from "@angular/common";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { NgbTooltipModule } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-string-select",
  standalone: true,
  imports: [CommonModule, NgbTooltipModule],
  templateUrl: "./string-select.component.html",
  styleUrl: "./string-select.component.css",
})
export class StringSelectComponent implements OnInit {
  @Input() values: any[] = [];
  @Input() name: string;
  @Input() cartName: string = "cartname";
  @Output() onChange: EventEmitter<never> = new EventEmitter<never>();
  info: string = "";

  constructor(public sanitizer: DomSanitizer) {}
  ngOnInit(): void {
    if (this.values.length > 0) {
      this.info = this.values[0].html;
    }
  }

  onInputChange(event: any) {
    var index = this.values.findIndex((x) => x.id == event.target.value);
    this.info = this.values[index].html;
    this.onChange.emit();
  }
}
