<div class="container mt-5">
  <h3>
    <b>{{ "classic-hosting-services.title" | translate }}</b>
  </h3>
  <p>
    {{ "classic-hosting-services.p1" | translate }}
  </p>
  <p>
    {{ "classic-hosting-services.p2" | translate }}
  </p>
  <p>
    {{ "classic-hosting-services.p3" | translate }}
  </p>
  <app-contact-btn></app-contact-btn>
</div>
