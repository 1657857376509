<div class="input-group">
  <span
    class="bi bi-info-circle input-group-text fs-5"
    [ngbTooltip]="tooltip"
  ></span>
  <select
    class="form-select input-extra"
    [name]="name"
    [attr.data-cartname]="cartName"
    (change)="onInputChange($event)"
  >
    <option *ngFor="let value of values" [value]="value.id">
      {{ value.sname }}
    </option>
  </select>
</div>

<ng-template #tooltip>
  <div [innerHTML]="sanitizer.bypassSecurityTrustHtml(info)"></div>
</ng-template>
