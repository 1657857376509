export const environment = {
  production: false,
  apiUrl: "https://test2-backend.clmaster.cloud/api/",
  apiHost: "test2-backend.clmaster.cloud",
  xoaUri: "test-xoa.clmaster.cloud:30443/",
  molin: true,
  revolut_sabdbox: true,
  googleAuthClientId:
    "1088218488817-k626cgnuuts9bfd9km1n7nk3qrpqn90d.apps.googleusercontent.com",
  leadconnectorhq_hu: "673c6f32e0b3914c4ff2bf56",
  leadconnectorhq_en: "673c6e159e613471cb1127e0",
};
