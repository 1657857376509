<div class="container mt-5">
  <h3>
    <b>{{ "automatization.title" | translate }}</b>
  </h3>
  <p>
    {{ "automatization.p1" | translate }}
  </p>
  <p>
    {{ "automatization.p2" | translate }}
  </p>
  <p>
    {{ "automatization.p3" | translate }}
  </p>
  <p>
    {{ "automatization.p4" | translate }}
  </p>
  <app-contact-btn></app-contact-btn>
</div>
