import { Component } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { environment } from "../../../../environments/environment";

@Component({
  selector: "app-contact-btn",
  standalone: true,
  imports: [TranslateModule],
  templateUrl: "./contact-btn.component.html",
  styleUrl: "./contact-btn.component.scss",
})
export class ContactBtnComponent {
  isProd = environment.production;
}
