import { Component, EventEmitter, input, Input, Output } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { NgbTooltipModule } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule } from "@ngx-translate/core";

@Component({
  selector: "app-checkbox-input",
  standalone: true,
  imports: [TranslateModule, NgbTooltipModule],
  templateUrl: "./checkbox-input.component.html",
  styleUrl: "./checkbox-input.component.css",
})
export class CheckboxInputComponent {
  @Input() name = "";
  @Input() label = "";
  @Input() extra: any;
  @Input() cartName: string = "cartname";
  @Input() info: string = "";
  @Output() onChange: EventEmitter<never> = new EventEmitter<never>();

  constructor(public sanitizer: DomSanitizer) {}
}
