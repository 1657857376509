import {
  animate,
  group,
  query,
  style,
  transition,
  trigger,
} from "@angular/animations";

const animationNames = [
  "Home",
  "VpsCategory",
  "Vps",
  "DedicatedCategory",
  "Dedicated",
  "ServerColocation",
  "EduService",
  "SystemIntegration",
  "Automatization",
  "ClassicHostingService",
  "Blogs",
  "Blog",
  "About",
  "Faq",
  "Cart",
  "Register",
];

function transitionLists() {
  let transitionList: string[] = [];
  for (let i = 0; i < animationNames.length; i++) {
    let transitions = [];
    for (let j = i + 1; j < animationNames.length; j++) {
      transitions.push(`${animationNames[i]} => ${animationNames[j]}`);
    }
    transitionList.push(transitions.filter(Boolean).join(", "));
  }
  return transitionList.filter(Boolean).join(", ");
}

function transitionListReverse() {
  let transitionList: string[] = [];
  for (let i = animationNames.length - 1; i >= 0; i--) {
    let transitions = [];
    for (let j = i - 1; j >= 0; j--) {
      transitions.push(`${animationNames[i]} => ${animationNames[j]}`);
    }
    transitionList.push(transitions.filter(Boolean).join(", "));
  }
  return transitionList.filter(Boolean).join(", ");
}

export const slideInAnimation = trigger("routeAnimations", [
  transition(
    transitionLists(),

    [
      query(":enter, :leave", style({ position: "fixed", width: "100%" }), {
        optional: true,
      }),
      group([
        query(
          ":enter",
          [
            style({ transform: "translateX(100%)" }),
            animate("0.5s ease-in-out", style({ transform: "translateX(0%)" })),
          ],
          { optional: true }
        ),
        query(
          ":leave",
          [
            style({ transform: "translateX(0%)" }),
            animate(
              "0.5s ease-in-out",
              style({ transform: "translateX(-100%)" })
            ),
          ],
          { optional: true }
        ),
      ]),
    ]
  ),
  transition(transitionListReverse(), [
    query(":enter, :leave", style({ position: "fixed", width: "100%" }), {
      optional: true,
    }),
    group([
      query(
        ":enter",
        [
          style({ transform: "translateX(-100%)" }),
          animate("0.5s ease-in-out", style({ transform: "translateX(0%)" })),
        ],
        { optional: true }
      ),
      query(
        ":leave",
        [
          style({ transform: "translateX(0%)" }),
          animate("0.5s ease-in-out", style({ transform: "translateX(100%)" })),
        ],
        { optional: true }
      ),
    ]),
  ]),
]);
