import { Component, OnInit } from "@angular/core";
import { ApiService } from "../../Core/Services/Api/api.service";
import { CommonModule } from "@angular/common";
import { DomSanitizer } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-blogs",
  standalone: true,
  imports: [CommonModule],
  templateUrl: "./blogs.component.html",
  styleUrl: "./blogs.component.scss",
})
export class BlogsComponent implements OnInit {
  constructor(
    private apiService: ApiService,
    public sanitizer: DomSanitizer,
    private router: Router,
    private translateService: TranslateService
  ) {}
  onLangChange?: Subscription;
  blogs: any[] = [];

  ngOnInit(): void {
    this.onLangChange = this.translateService.onLangChange.subscribe((val) => {
      this.getApiCalls();
    });

    this.getApiCalls();
  }

  getApiCalls() {
    this.apiService.getBlogs().subscribe((response) => {
      this.blogs = response.data;
    });
  }

  onBlogClick(blog: any) {
    this.router.navigate(["/blogs", blog.url]);
  }
}
