import { environment } from "../../../environments/environment";

interface Scripts {
  name: string;
  src: string;
  async: boolean;
  defer: boolean;
  data_resources_url?: string;
  data_widget_id?: string;
}
export const ScriptStore: Scripts[] = [
  {
    name: "molin",
    src: "https://widget.molin.ai/shop-ai.js?w=pkqi93co",
    async: false,
    defer: false,
  },
  {
    name: "leadconnectorhq_en",
    src: "https://widgets.leadconnectorhq.com/loader.js",
    async: false,
    defer: false,
    data_resources_url:
      "https://widgets.leadconnectorhq.com/chat-widget/loader.js",
    data_widget_id: environment.leadconnectorhq_en,
  },
  {
    name: "leadconnectorhq_hu",
    src: "https://widgets.leadconnectorhq.com/loader.js",
    async: false,
    defer: false,
    data_resources_url:
      "https://widgets.leadconnectorhq.com/chat-widget/loader.js",
    data_widget_id: environment.leadconnectorhq_hu,
  },
  {
    name: "googleAuth",
    src: "https://accounts.google.com/gsi/client?hl=en_EN",
    async: true,
    defer: false,
  },
];
