<div class="input-group">
  <span
    class="bi bi-info-circle input-group-text fs-5"
    [ngbTooltip]="tooltip"
  ></span>
  <select
    [name]="name"
    [attr.data-cartname]="cartName"
    class="form-control form-select input-extra check"
    (change)="onChange.emit()"
  >
    <option value="0">{{ "vps-page.no" | translate }}</option>
    <option value="1">{{ "vps-page.yes" | translate }}</option>
  </select>
</div>

<ng-template #tooltip>
  <div [innerHTML]="sanitizer.bypassSecurityTrustHtml(info)"></div
></ng-template>
